import SalesManagerIcon from './SalesManagerIcon';

import SalesManagerList from './SalesManagerList';
import SalesManagerCreate from './SalesManagerCreate';
import SalesManagerEdit from './SalesManagerEdit';

export default {
    list: SalesManagerList,
    create: SalesManagerCreate,
    edit: SalesManagerEdit,
    icon: SalesManagerIcon,
};
