import React from 'react';
import { InputProps } from 'ra-core';
import {
    Datagrid,
    TextField,
    Filter,
    List,
    SearchInput,
    ShowButton,
    ReferenceInput,
    SelectInput,
AutocompleteInput,
} from 'react-admin';
// import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';
// import Chip from '@material-ui/core/Chip';
import AvatarField from './AvatarField';

// import ColoredNumberField from './ColoredNumberField';
// import MobileGrid from './MobileGrid';

const IntermediateFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn resettable/>
        <ReferenceInput
                        autoFocus
                        source="id"
                        reference="intermediates"
                        label="Code"
                        filter={{Etat:{}}}
                        perPage={100}
                        resettable
                        alwaysOn
                    >

                         <AutocompleteInput
                            optionText="Code"
                            optionValue="id"
                            emptyText='Tout les intermédiares'

                        />
                    </ReferenceInput>
    </Filter>
);

// const useStyles = makeStyles({

// });

interface Props extends Omit<InputProps, 'source'> {
    source?: string;
}

const IntermediateList = (props: any) => {
    // const classes = useStyles();
    // const isXsmall = useMediaQuery<Theme>(theme =>
    //     theme.breakpoints.down('xs')
    // );
    return (
        <List
            {...props}
            filters={<IntermediateFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            bulkActionButtons={false}
        >

                <Datagrid rowClick="show">
                    <AvatarField
                        size="45"
                    />

                    {/* <IntermediateThumbnailField label="resources.intermediates.fields.name"/> */}

                    <TextField
                        source="Code"
                        label="resources.intermediates.fields.code"
                    />
                    <TextField
                        source="RS"
                        label="resources.intermediates.fields.name"
                    />
                     <TextField
                        source="Etat"
                        label="resources.intermediates.fields.status"
                    />
                     <TextField
                        source="Adresse"
                        label="resources.intermediates.fields.address"
                    />
                    <TextField
                        source="Email"
                        label="resources.intermediates.fields.email"
                    />
                     {/* <TextField
                        source="region"
                        label="resources.intermediates.fields.region"
                    /> */}
                     <TextField
                        source="Ville"
                        label="resources.intermediates.fields.city"
                    />
                    <TextField
                        source="Tél"
                        label="resources.intermediates.fields.phone"
                    />

                    <ShowButton />
                </Datagrid>

        </List>
    );
};

export default IntermediateList;
