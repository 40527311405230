import React, { FC } from 'react';
import {
    Edit,
    Toolbar,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    TextInput,
    required,
    TabbedForm,
    FormTab,
    RadioButtonGroupInput,
    FileInput,
    FileField,
} from 'react-admin';

import DownloadDocument from './DownloadDocument';

import RichTextInput from 'ra-input-rich-text';

// import { DateInput, TimeInput, DateTimeInput } from 'react-admin-date-inputs';
import { Typography, Box, Card, CardContent } from '@material-ui/core';

// import MapField from './MapField';

import { Styles } from '@material-ui/styles/withStyles';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Aside from './Aside';
import FullNameField from './FullNameField';
import { Visit, FieldProps } from '../types';

export const styles: Styles<Theme, any> = {
    image: { width: 544 },
    title: { width: 544 },
    type: { width: 544 },
};

const VisitEdit = (props: any) => {
    const classes = useStyles();
    return (
        <Edit
            undoable={false}
            title={<VisitName />}
            aside={<Aside />}
            {...props}
        >
            <TabbedForm>
                <FormTab label="Document">
                <DownloadDocument />
                {/* <FileInput
                    source="file"
                    label="Fichier"
                    labelSingle="resources.documentation.file.upload_single"
                    accept="image/*, viedo/*, application/pdf"
                    multiple={false}
                    formClassName={classes.file}
                    fullWidth={true}
                >
                    <FileField  accept="image/*, viedo/*, application/pdf" source="url" title="title" />
                </FileInput> */}
                <TextInput
                    source="title"
                    validate={requiredValidate}
                    fullWidth={true}
                    formClassName={classes.title}
                    label="resources.documentation.fields.title"
                />
                    <SelectInput
                        label="resources.documentation.fields.type"
                        source="type"
                        choices={[
                            {
                                id: 'assistance',
                                name: 'Assistance',
                            },
                            {
                                id: 'collaboration',
                                name: 'Collaboration',
                            },
                            {
                                id: 'guide_applications',
                                name: 'guide_applications',
                            },
                            {
                                id: 'partenaires_et_experts',
                                name: 'Partenaires et experts',
                            },
                            {
                                id: 'production',
                                name: 'Production',
                            },
                        ]}
                        validate={requiredValidate}
                        formClassName={classes.type}
                        fullWidth={true}
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};


const VisitName: FC<FieldProps<Visit>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const useStyles = makeStyles(styles);

const requiredValidate = [required()];
// const Separator = () => <Box pt="1em" />;

export const SectionTitle = ({ label }: { label: string }) => {
    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};
export default VisitEdit;
