import React from 'react';
import {
    Create,
    SimpleForm,
    required,
    TextInput,
    SelectInput,
    AutocompleteInput,
    FileInput,
    FileField,
} from 'react-admin';

// import { DateInput, TimeInput, DateTimeInput } from 'react-admin-date-inputs';
// import MapField from './MapField';

import {Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Styles } from '@material-ui/styles/withStyles';

// const validateEmail = email();

export const styles: Styles<Theme, any> = {
    title: { width: 544 },
    type: { width: 544 },
    file: { width: 544 },
};

const useStyles = makeStyles(styles);

const VisitCreate = (props: any) => {
    const classes = useStyles();

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <FileInput
                    source="file"
                    label="Fichier"
                    labelSingle="resources.documentation.file.upload_single"
                    accept="image/*, video/*, application/*"
                    multiple={false}
                    formClassName={classes.file}
                    fullWidth={true}
                >
                    <FileField  accept="image/*, viedo/*, application/*" source="viewUrl" title="title" />
                </FileInput>
                <Separator />

                <TextInput
                    source="title"
                    validate={requiredValidate}
                    fullWidth={true}
                    formClassName={classes.title}
                    label="resources.documentation.fields.title"
                />
                <SelectInput
                    label="resources.documentation.fields.type"
                    source="type"
                    choices={[
                        {
                            id: 'assistance',
                            name: 'Assistance',
                        },
                        {
                            id: 'collaboration',
                            name: 'Collaboration',
                        },
                        {
                            id: 'guide_applications',
                            name: 'guide_applications',
                        },
                        {
                            id: 'partenaires_et_experts',
                            name: 'Partenaires et experts',
                        },
                        {
                            id: 'production',
                            name: 'Production',
                        },
                    ]}
                    validate={requiredValidate}
                    fullWidth={true}
                    formClassName={classes.type}
                />
            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

const Separator = () => <Box pt="1em" />;

export default VisitCreate;
