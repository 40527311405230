import React from 'react';
import {
    DateField,
    useTranslate,
} from 'react-admin';
import PropTypes from 'prop-types';
import {
    Typography,
    Card,
    CardContent,
    Box,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { makeStyles } from '@material-ui/core/styles';
;

const useAsideStyles = makeStyles(theme => ({
    root: {
        width: 400,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    card: {
        marginBottom:20,
    },
}));

const Aside = ({ record, basePath }) => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            {record && <EventList record={record} basePath={basePath} />}
        </div>
    );
};

Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
};

const EventList = ({ record, basePath }) => {
    // const classes = useAsideStyles();
    const translate = useTranslate();

 
    // const events = mixOrdersAndReviews(orders, orderIds, reviews, reviewIds);

    return (
        <>
            <Box m="0 0 1em 1em">
      
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.intermediates.fields.history')}
                        </Typography>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                    <AccessTimeIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.intermediates.fields.created_at'
                                            )}
                                        </Typography>
                                        <DateField
                                            record={record}
                                            source="created_at"
                                        />
                                    </Box>
                                </Box>
                        
                            </Box>
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <AccessTimeIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.intermediates.fields.updated_at'
                                            )}
                                        </Typography>
                                        <DateField
                                            record={record}
                                            source="updated_at"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>

       
        </>
    );
};

export default Aside;
