import React, { FC } from 'react';
import {
    useMutation,
    showNotification as showNotificationAction,
} from 'react-admin';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const API_URL = process.env.REACT_APP_PUBLIC_URL;

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
}));

const DownloadDocument: FC<any> = ({ record, showNotification }) => {
    const classes = useStyles();
    const [mutate, { loading }] = useMutation();
    // const approve = event => mutate({
    //     type: 'update',
    //     resource: 'comments',
    //     payload: {
    //         id: event.target.dataset.id,
    //         data: { isApproved: true, updatedAt: new Date() }
    //     },
    // });

    const viewFile = () => {
        const url = `${API_URL}${record.viewUrl}`;
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        // link.setAttribute("download", `${"Report-" + Date.now() + ".pdf"}`);
        // // 3. Append to html page
        document.body.appendChild(link);
        // // 4. Force download
        link.click();
        // // 5. Clean up and remove the link
        link?.parentNode?.removeChild(link);
    };
    const downloadFile = () => {
        const url = `${API_URL}${record.url}`;
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        // link.setAttribute("download", `${"Report-" + Date.now() + ".pdf"}`);
        // // 3. Append to html page
        document.body.appendChild(link);
        // // 4. Force download
        link.click();
        // // 5. Clean up and remove the link
        link?.parentNode?.removeChild(link);
    };
    return (
        <>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<GetAppIcon />}
                onClick={downloadFile}
                // fullWidth
            >
                {loading && <CircularProgress size={25} thickness={2} />}
                Télécharger le document
            </Button>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<VisibilityIcon />}
                onClick={viewFile}
                // fullWidth
            >
                {loading && <CircularProgress size={25} thickness={2} />}
                Visualiser le document
            </Button>
        </>
    );
};

export default DownloadDocument;
