import React, { SVGProps } from 'react';

const VisitIcon = (props: SVGProps<SVGSVGElement>) => (


<svg width="39px" height="35px" viewBox="0 0 39 35" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="checlklist">
            <g id="Group" transform="translate(19.500000, 17.500000) scale(-1, 1) rotate(-180.000000) translate(-19.500000, -17.500000) ">
                <path d="M12.8466187,34.6286093 C12.2994964,34.4300582 11.4652518,34.0668809 11.0167986,33.8272625 C9.93143885,33.2655341 8.36208633,32.1013672 7.52784173,31.245026 L6.87316547,30.5668809 L6.05669065,31.1078972 C5.3571223,31.5735639 5.20467626,31.6489134 4.90866906,31.6489134 C4.45133094,31.6489134 4.12820144,31.409295 4.12820144,31.0736149 C4.12820144,30.8611366 4.28064748,30.6968677 5.33888489,29.751607 C6.10111511,29.0734619 6.66647482,28.6352923 6.84557554,28.5802979 C7.2673741,28.436384 7.75136691,28.5667279 8.02071942,28.8952658 C8.12827338,29.0323947 8.49629496,29.4844914 8.83672662,29.8883787 C10.1194245,31.4567901 11.7967986,32.8748597 13.6621583,33.9708193 C14.1915108,34.2790021 14.6217266,34.5804 14.6217266,34.6353943 C14.6217266,34.6971738 14.5768345,34.7996633 14.5319424,34.8750128 C14.3715468,35.0942761 13.9319784,35.0328538 12.8466187,34.6286093 Z" id="Path" fill="#000000"></path>
                <path d="M0.639244604,34.6421794 C0.522805755,34.6011121 0.334352518,34.4711254 0.235683453,34.3682787 C0.0472302158,34.1765126 0.0472302158,34.1286603 0.0472302158,30.0255076 L0.0472302158,25.8816447 L0.334352518,25.6898786 C0.513920863,25.5666769 0.765035971,25.4774003 1.00726619,25.4502602 C1.2223741,25.4299051 3.61755396,25.415978 6.32604317,25.4299051 C10.8648201,25.4502602 11.2861511,25.4574023 11.5106115,25.573819 C12.039964,25.8341496 12.0217266,25.7655851 12.0039568,28.7245689 L11.9768345,31.4439343 L11.3848201,30.8754209 C11.0528058,30.567238 10.5776978,30.0876441 10.3265827,29.8069585 L9.8692446,29.2930823 L9.8692446,28.2106928 L9.8692446,27.1283032 L6.05715827,27.1283032 L2.24460432,27.1283032 L2.24460432,30.073717 L2.24460432,33.0191307 L4.97133094,33.0191307 L7.70694245,33.0191307 L8.3705036,33.4847975 C8.72917266,33.745128 9.29453237,34.1150903 9.62607914,34.3136415 C9.95809353,34.5121926 10.2269784,34.6903887 10.2269784,34.7039588 C10.2269784,34.7175288 8.11892086,34.731456 5.54464029,34.731456 C2.0023741,34.731456 0.80057554,34.7107438 0.639244604,34.6421794 Z" id="Path" fill="#1199BC"></path>
                <path d="M16.9902518,33.1559025 C16.8915827,33.1284053 16.6942446,33.0052036 16.5689209,32.8820018 C16.0933453,32.4574023 16.3178058,31.8271095 17.0084892,31.6078461 C17.4658273,31.4639323 37.898741,31.4639323 38.3471942,31.6078461 C38.9480935,31.7996123 39.1814388,32.3681257 38.8405396,32.7927252 C38.4729856,33.251607 39.2263309,33.2241098 27.6553957,33.2173248 C21.8965827,33.2176819 17.0982734,33.1901847 16.9902518,33.1559025 Z" id="Path" fill="#1199BC"></path>
                <path d="M16.9902518,29.4298541 C16.3804676,29.2245179 16.1382374,28.6627895 16.4791367,28.2174778 C16.8017986,27.8064483 16.6315827,27.8132333 23.1615108,27.8132333 C28.8932014,27.8132333 29.1171942,27.8200184 29.3683094,27.9435772 C30.1216547,28.3271095 30.0229856,29.1627385 29.188741,29.4298541 C28.7585252,29.5666259 17.4031655,29.5666259 16.9902518,29.4298541 Z" id="Path" fill="#1199BC"></path>
                <path d="M0.567230216,21.8538414 C-0.0158992806,21.5867258 0.0023381295,21.7238547 0.0023381295,17.3193042 C0.0023381295,13.4357719 0.0112230216,13.3189981 0.181906475,13.1000918 C0.522805755,12.6687073 0.450791367,12.6754923 6.11093525,12.6754923 C11.7079496,12.6754923 11.6359353,12.6687073 11.9950719,13.0729517 C12.147518,13.2440057 12.1564029,13.5114784 12.1564029,17.3335884 C12.1564029,21.2653301 12.147518,21.416029 11.9768345,21.6077951 C11.6359353,21.9913274 11.6808273,21.9913274 6.03892086,21.9913274 C1.05169065,21.9909703 0.845467626,21.9841853 0.567230216,21.8538414 Z M9.86877698,17.3332313 L9.86877698,14.3878176 L6.05669065,14.3878176 L2.24460432,14.3878176 L2.24460432,17.3332313 L2.24460432,20.278645 L6.05669065,20.278645 L9.86877698,20.278645 L9.86877698,17.3332313 Z" id="Shape" fill="#1199BC" fillRule="nonzero"></path>
                <path d="M16.9902518,20.3882767 C16.3804676,20.1829405 16.1382374,19.6212121 16.4791367,19.1759004 C16.8111511,18.7513009 16.1920144,18.771656 27.6731655,18.771656 C39.2258633,18.771656 38.472518,18.7441588 38.8400719,19.2030405 C39.1809712,19.62764 38.9476259,20.1961535 38.3467266,20.3882767 C37.8982734,20.5250485 17.4209353,20.5318335 16.9902518,20.3882767 Z" id="Path" fill="#1199BC"></path>
                <path d="M16.6315827,16.4840322 C16.3804676,16.2922661 16.3266906,16.1962045 16.3266906,15.9701561 C16.3266906,15.8126722 16.3893525,15.5934088 16.4613669,15.4837772 C16.7484892,15.0591776 16.5422662,15.0727477 23.0988489,15.0727477 C29.6554317,15.0727477 29.4492086,15.0591776 29.7363309,15.4837772 C29.8078777,15.5934088 29.8710072,15.8126722 29.8710072,15.9701561 C29.8710072,16.1962045 29.8172302,16.291909 29.5661151,16.4840322 L29.261223,16.7168656 L23.0988489,16.7168656 L16.9364748,16.7168656 L16.6315827,16.4840322 Z" id="Path" fill="#1199BC"></path>
                <path d="M0.531223022,9.16156515 C0.369892086,9.07943067 0.181438849,8.92873176 0.118776978,8.83945516 C0.0289928058,8.70946842 0.0023381295,7.72992552 0.0023381295,4.66131007 C0.0023381295,0.763850627 0.0112230216,0.647433935 0.181906475,0.428170595 C0.522805755,-0.00321395776 0.450791367,0.00357106418 6.11093525,0.00357106418 C11.7079496,0.00357106418 11.6359353,-0.00321395776 11.9950719,0.400673401 C12.147518,0.571727375 12.1564029,0.852770125 12.1835252,4.53096623 C12.2106475,8.88730742 12.2106475,8.88730742 11.6724101,9.16120804 L11.367518,9.31869197 L6.10158273,9.31869197 L0.836115108,9.31869197 L0.531223022,9.16156515 Z M9.86877698,4.66131007 L9.86877698,1.71589634 L6.05669065,1.71589634 L2.24460432,1.71589634 L2.24460432,4.66131007 L2.24460432,7.6067238 L6.05669065,7.6067238 L9.86877698,7.6067238 L9.86877698,4.66131007 Z" id="Shape" fill="#1199BC" fillRule="nonzero"></path>
                <path d="M16.9902518,7.71635547 C16.5689209,7.57244159 16.3266906,7.29854097 16.3266906,6.94929089 C16.3266906,6.70253035 16.3804676,6.60004081 16.6315827,6.38756249 L16.9364748,6.13401694 L27.5296043,6.11330476 C39.2436331,6.09259259 38.472518,6.0654525 38.8400719,6.53111927 C39.1809712,6.9557188 38.9476259,7.52423222 38.3467266,7.71599837 C37.8982734,7.85312723 17.4209353,7.86026936 16.9902518,7.71635547 Z" id="Path" fill="#1199BC"></path>
                <path d="M16.6315827,3.81211101 C16.3804676,3.62034486 16.3266906,3.52464034 16.3266906,3.29823487 C16.3266906,2.93505765 16.5240288,2.63401694 16.8649281,2.50367309 C17.2596043,2.34618916 28.9380935,2.34618916 29.3327698,2.50367309 C29.6736691,2.63365983 29.8710072,2.93505765 29.8710072,3.29823487 C29.8710072,3.52428324 29.8172302,3.61998776 29.5661151,3.81211101 L29.261223,4.04494439 L23.0988489,4.04494439 L16.9364748,4.04494439 L16.6315827,3.81211101 Z" id="Path" fill="#1199BC"></path>
            </g>
        </g>
    </g>
</svg>
);

export default VisitIcon;
