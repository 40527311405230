import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    useTranslate,
    AutocompleteInput,
    SelectInput,
    required,
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

//morocco regions list
import regions from "../data/regions.json";

export const styles: Styles<Theme, any> = {
    name: { display: 'inline-block' },
    code: { display: 'inline-block', marginLeft: 32 },
    status: { maxWidth: 544 },
    email:  { display: 'inline-block' },
    region: { display: 'inline-block', marginLeft: 32 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    gsmPhone: { maxWidth: 544 },
    fixPhone: { display: 'inline-block' },
    fax: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    password: { display: 'inline-block' },
    confirm_password: { display: 'inline-block', marginLeft: 32 },
};

const useStyles = makeStyles(styles);

export const validatePasswords = ({
    password,
    confirm_password,
}: {
    password: string;
    confirm_password: string;
}) => {
    const errors = {} as any;

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = [
            'resources.intermediates.errors.password_mismatch',
        ];
    }

    return errors;
};

const IntermediateCreate = (props: any) => {
    const classes = useStyles();


    return (
        <Create {...props}>
            <SimpleForm   
             redirect="list"
             resource="intermediates"
            validate={validatePasswords}>
                <SectionTitle label="resources.intermediates.fieldGroups.identity" />
                <TextInput
                    autoFocus
                    source="name"
                    formClassName={classes.name}
                    label="resources.intermediates.fields.name"
                    validate={requiredValidate}
                />
                <TextInput
                    source="code"
                    formClassName={classes.code}
                    label="resources.intermediates.fields.code"
                    validate={requiredValidate}
                />
                <Separator />
                <SelectInput
                    source="status"
                    label="resources.intermediates.fields.status"
                    choices={[
                        { id: 'enabled', name: 'Activé' },
                        { id: 'disabled', name: 'Désactivé' },
                    ]}
                    formClassName={classes.status}
                    validate={requiredValidate}
                    fullWidth={true}
                />
                 <Separator />
                <TextInput
                    type="email"
                    source="email"
                    label="resources.intermediates.fields.email"
                    validation={{ email: true }}
                    formClassName={classes.email}
                    validate={requiredValidate}
                />
               
                 <AutocompleteInput
                    source="region"
                    label="resources.intermediates.fields.region"
                    validate={requiredValidate}
                    choices={regions}
                    formClassName={classes.region}
                    optionText="value"
                    optionValue="id"
                  />
                  
                {/* <Separator /> */}
                <SectionTitle label="resources.intermediates.fieldGroups.address" />
                <TextInput
                    source="address"
                    label="resources.intermediates.fields.address"
                    formClassName={classes.address}
                    multiline={true}
                    fullWidth={true}
                />
                <TextInput 
                source="zipcode" 
                label="resources.intermediates.fields.zipcode"
                formClassName={classes.zipcode} />
                <TextInput source="city" formClassName={classes.city} />
                {/* <Separator /> */}
                <SectionTitle label="resources.intermediates.fieldGroups.phone" />
                <TextInput
                    source="gsmPhone"
                    fullWidth={true}
                    validate={requiredValidate}
                    label="resources.intermediates.fields.gsmPhone"
                    formClassName={classes.gsmPhone}
                />
                <TextInput
                    source="fixPhone"
                    label="resources.intermediates.fields.fixPhone"
                    formClassName={classes.fixPhone}
                />
                <TextInput
                    source="fax"
                    label="resources.intermediates.fields.fax"
                    formClassName={classes.fax}
                />

            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

export const  SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default IntermediateCreate;
