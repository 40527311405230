import englishMessages from 'ra-language-english';

export default {
    ...englishMessages,
    global: {
        auth: {
            email: 'Email',
            sign_in: 'Login',
            password: 'Password',
            sign_in_error: 'Signin Failed',
        },
    },

    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            new_customers: 'New Customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to react-admin demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                aor_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
        menu: {
            sales: 'Sales',
            catalog: 'Catalog',
            customers: 'Customers',
        },
    },
    resources: {
        dashboard: {
            name: 'Dashboard'
        },
        areamanagers: {
            name: 'Area Manager |||| Areas Managers',
            fields: {
                first_name:'Fist Name',
                last_name:'Last Name',
                role:'Role',
                email:'Email',
                region:'Region',
                gsmPhone:'GSM Phone',
                fixPhone:'Fix Phone',
                fax:'FAX',
                history:'History',
                address:'Address',
                city:'City',
                created_at: 'Created At',
                updated_at: 'Updated At',
                commands: 'Orders',
                first_seen: 'First seen',
                zipcode: 'Zip Code',
                name: 'Name',
                password: 'Password',
                confirm_password: 'Confirm password',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                phone: 'Phone',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        salesmanagers: {
            name: 'Sales Manager |||| Sales Managers',
            fields: {
                first_name:'Fist Name',
                last_name:'Last Name',
                role:'Role',
                email:'Email',
                region:'Region',
                gsmPhone:'GSM Phone',
                fixPhone:'Fix Phone',
                fax:'FAX',
                history:'History',
                address:'Address',
                city:'City',
                created_at: 'Created At',
                updated_at: 'Updated At',
                commands: 'Orders',
                first_seen: 'First seen',
                zipcode: 'Zip Code',
                name: 'Name',
                password: 'Password',
                confirm_password: 'Confirm password',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                phone: 'Phone',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        accountmanagers: {
            name: 'Account Manager |||| Account Managers',
            fields: {
                first_name:'Fist Name',
                last_name:'Last Name',
                role:'Role',
                email:'Email',
                region:'Region',
                gsmPhone:'GSM Phone',
                fixPhone:'Fix Phone',
                fax:'FAX',
                history:'History',
                address:'Address',
                city:'City',
                created_at: 'Created At',
                updated_at: 'Updated At',
                commands: 'Orders',
                first_seen: 'First seen',
                zipcode: 'Zip Code',
                name: 'Name',
                password: 'Password',
                confirm_password: 'Confirm password',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                phone: 'Phone',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        intermediates: {
            name: 'Intermediate |||| Intermediates',
            fields: {
                code:'Code',
                status:'Status',
                name:'Name',
                first_name:'Fist Name',
                last_name:'Last Name',
                role:'Role',
                email:'Email',
                region:'Region',
                phone:'Phone',
                gsmPhone:'GSM Phone',
                fixPhone:'Fix Phone',
                fax:'FAX',
                history:'History',
                address:'Address',
                city:'City',
                created_at: 'Created At',
                updated_at: 'Updated At',
                commands: 'Orders',
                first_seen: 'First seen',
                zipcode: 'Zip Code',
                password: 'Password',
                confirm_password: 'Confirm password',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                phone: 'Phone',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        visits: {
            name: 'Visit |||| Visits',
            fields: {
            status:'Status',
            date:'Visit Date',
            accountmanager:'Account Manager',
            accountmanagers:'Account Managers',
            accountmanager_name:'Account Manager',
            areamanager:'Area Manager',
            areamanager_name:'Area Manager',
            intermediate:'Intermediate',
            intermediate_name:'Intermediate Name',
            intermediate_code:'Intermediate Code',
            history:'History',
            created_at: 'Created At',
            updated_at: 'Updated At',
            },
        },
        documentation: {
            name: 'Documentation |||| Documentation',
            fields: {
            title:'Title',
            type:'type',
            history:'History',
            created_at: 'Created At',
            updated_at: 'Updated At',
            },
            file: {
                upload_several:
                    'Drop some files to upload, or click to select one.',
                upload_single:
                    'Drop a file to upload, or click to select it.',
            },
        },
        clients: {
            name: 'Client |||| Clients',
        },
        settings: {
            name: 'Setting |||| Settings',
        },
        notifications: {
            name: 'Notification |||| Notifications',
        },
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                first_name:'Fist Name',
                last_name:'Last Name',
                email:'Email',
                phone:'Phone',
                history:'History',
                created_at: 'Created At',
                updated_at: 'Updated At',
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        commands: {
            name: 'Order |||| Orders',
            amount: '1 order |||| %{smart_count} orders',
            title: 'Order %{reference}',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    total: 'Total',
                    unit_price: 'Unit Price',
                },
                customer_id: 'Customer',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned',
            },
        },
        invoices: {
            name: 'Invoice |||| Invoices',
            fields: {
                date: 'Invoice date',
                customer_id: 'Customer',
                command_id: 'Order',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                address: 'Address',
            },
        },
        products: {
            name: 'Poster |||| Posters',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
            },
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
            },
        },
        categories: {
            name: 'Category |||| Categories',
            fields: {
                products: 'Products',
            },
        },
        reviews: {
            name: 'Review |||| Reviews',
            amount: '1 review |||| %{smart_count} reviews',
            relative_to_poster: 'Review on poster',
            detail: 'Review detail',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Accept',
                reject: 'Reject',
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            },
        },
        segments: {
            name: 'Segments',
            fields: {
                customers: 'Customers',
                name: 'Name',
            },
            data: {
                compulsive: 'Compulsive',
                collector: 'Collector',
                ordered_once: 'Ordered once',
                regular: 'Regular',
                returns: 'Returns',
                reviewer: 'Reviewer',
            },
        },
    },
};
