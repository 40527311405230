import React from 'react';
import { InputProps } from 'ra-core';
import {
    Datagrid,
    TextField,
   DateField,
    Filter,
    List,
    SearchInput,
    EditButton,
    DateInput,
} from 'react-admin';

// import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';
import AvatarField from './AvatarField';
const FileSaver = require('file-saver');
// import ColoredNumberField from './ColoredNumberField';
// import MobileGrid from './MobileGrid';

const VisitFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <DateInput source="strDate" label="Date" />
    </Filter>
);

// const useStyles = makeStyles({

// });

interface Props extends Omit<InputProps, 'source'> {
    source?: string;
}
const excelExporter = (records, fetchRelatedRecords) => {
    const excelUrl = `${process.env.REACT_APP_ADMIN_REST_API_URL}/visits/xls`;
    FileSaver.saveAs(excelUrl, 'liste des visites.xlsx');
};


const VisitList = (props: any) => {
    // const classes = useStyles();
    // const isXsmall = useMediaQuery<Theme>(theme =>
    //     theme.breakpoints.down('xs')
    // );
    return (
        <List
            {...props}
            filters={<VisitFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={(() => {
                return excelExporter ;
            })()}
            bulkActionButtons={false}
        >

                <Datagrid rowClick="show">
                    <AvatarField
                        size="45"
                    />
                      <DateField
                        source="date"
                        label="resources.visits.fields.date"
                    />
                     <TextField
                        source="status"
                        label="resources.visits.fields.status"
                    />
                     <TextField
                        source="areaManager.name.full"
                        label="resources.visits.fields.areamanager_name"
                    />
                      {/* <TextField
                        source="accountManager.name.full"
                        label="resources.visits.fields.accountmanager_name"
                    /> */}
                    <TextField
                        source="intermediate.RS"
                        label="resources.visits.fields.intermediate_name"
                    />

                    <TextField
                        source="intermediate.Code"
                        label="resources.visits.fields.intermediate_code"
                    />

                    <EditButton />
                </Datagrid>

        </List>
    );
};

export default VisitList;
