import DocumentIcon from './DocumentIcon';

import DocumentList from './DocumentList';
import DocumentCreate from './DocumentCreate';
import DocumentEdit from './DocumentEdit';

export default {
    list: DocumentList,
    create: DocumentCreate,
    edit: DocumentEdit,
    icon: DocumentIcon,
};
