import AreaManagerIcon from './AreaManagerIcon';
// import AreaManagerIcon from '@material-ui/icons/PersonPin';

import AreaManagerList from './AreaManagerList';
import AreaManagerCreate from './AreaManagerCreate';
import AreaManagerEdit from './AreaManagerEdit';

export default {
    list: AreaManagerList,
    create: AreaManagerCreate,
    edit: AreaManagerEdit,
    icon: AreaManagerIcon,
};
