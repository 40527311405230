import React, { FC } from 'react';
import {
    Show,
    TextInput,
    Toolbar,
    SelectInput,
    required,
    AutocompleteInput,
    FormWithRedirect,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';

import { Styles } from '@material-ui/styles/withStyles';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Aside from './Aside';
import FullNameField from './FullNameField';
import { Intermediate, FieldProps } from '../types';

import { SectionTitle } from './IntermediateCreate';

//morocco regions list
import regions from '../data/regions.json';

export const styles: Styles<Theme, any> = {
    image: { width: 544 },
    name: { width: 544 },
};

const IntermediateShow = (props: any) => {
    return (
        <Show
            undoable={false}
            title={<IntermediateName />}
            aside={<Aside />}
            component="div"
            {...props}
        >
            <IntermediateForm />
        </Show>
    );
};

const IntermediateName: FC<FieldProps<Intermediate>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const useStyles = makeStyles(styles);

export const validatePasswords = ({
    password,
    confirm_password,
}: {
    password: string;
    confirm_password: string;
}) => {
    const errors = {} as any;

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = [
            'resources.intermediates.errors.password_mismatch',
        ];
    }

    return errors;
};

const IntermediateForm = (props: any) => {
    // const translate = useTranslate();
    const classes = useStyles();
    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            validate={validatePasswords}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <SectionTitle label="resources.intermediates.fieldGroups.identity" />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="RS"
                                                label="resources.intermediates.fields.name"
                                                disabled
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="Code"
                                                label="resources.intermediates.fields.code"
                                                fullWidth
                                                disabled
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="Etat"
                                                label="resources.intermediates.fields.status"
                                                disabled
                                                formClassName={classes.status}
                                                fullWidth={true}
                                            />
                                        </Box>
                                    </Box>

                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                type="email"
                                                source="Email"
                                                label="resources.intermediates.fields.email"
                                                fullWidth
                                                disabled
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <AutocompleteInput
                                                source="Ville"
                                                label="resources.intermediates.fields.city"
                                                formClassName={classes.region}
                                                optionText="value"
                                                optionValue="id"
                                                fullWidth
                                                disabled
                                            />
                                        </Box>
                                    </Box>
                                    <SectionTitle label="resources.intermediates.fieldGroups.address" />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="Adresse"
                                                label="resources.intermediates.fields.address"
                                                formClassName={classes.address}
                                                multiline={true}
                                                fullWidth={true}
                                                disabled
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        {/* <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="zipcode"
                                                label="resources.intermediates.fields.zipcode"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="city"
                                                label="resources.intermediates.fields.city"
                                                fullWidth
                                            />
                                        </Box> */}
                                    </Box>
                                    <SectionTitle label="resources.intermediates.fieldGroups.phone" />
                                    {/* <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="gsmPhone"
                                                validate={requiredValidate}
                                                label="resources.intermediates.fields.gsmPhone"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box> */}
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="Tél"
                                                label="resources.intermediates.fields.phone"
                                                fullWidth
                                                disabled
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="Fax"
                                                label="resources.intermediates.fields.fax"
                                                fullWidth
                                                disabled
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        {/* <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="intermediates"
                        /> */}
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];
// const Separator = () => <Box pt="1em" />;
export default IntermediateShow;
