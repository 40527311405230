import React, { FC } from 'react';
import {
    Edit,
    SelectInput,
    DateTimeInput,
    required,
    TabbedForm,
    FormTab,
    ReferenceField,
    TextField,
    RadioButtonGroupInput,
    SingleFieldList,
    ChipField,
    Toolbar,
    SaveButton,
    ArrayField,
} from 'react-admin';

// import RichTextInput from 'ra-input-rich-text';

// import { DateInput, TimeInput, DateTimeInput } from 'react-admin-date-inputs';
import { Typography, Box, Card, CardContent } from '@material-ui/core';

// import MapField from './MapField';

import { Styles } from '@material-ui/styles/withStyles';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Aside from './Aside';
import FullNameField from './FullNameField';
import { Visit, FieldProps } from '../types';

import PrintPdfVisitButton from './PrintPdfVisitButton';

export const styles: Styles<Theme, any> = {
    image: { width: 544 },
    name: { width: 544 },
};

const VisitEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
        {/* Add your custom actions */}
        <PrintPdfVisitButton {...props} />
    </Toolbar>
);

const VisitEdit = (props: any) => {
    const classes = useStyles();
    return (
        <Edit
            undoable={false}
            title={<VisitName />}
            aside={<Aside />}
            {...props}
        >
            <TabbedForm toolbar={<VisitEditToolbar />} {...props}>
                {/* <FormTab >  </FormTab > */}
                <FormTab label="Visite">
                    <ReferenceField
                        label="resources.visits.fields.areamanager"
                        source="areaManager.id"
                        reference="areaManagers"
                        link="show"
                    >
                        <TextField source="name.full" />
                    </ReferenceField>

                    <ArrayField source="accountManagers" label="resources.visits.fields.accountmanagers" >
                    <SingleFieldList {...props}>
                            <ChipField source="name.full" />
                        </SingleFieldList>
                   </ArrayField>

                    <DateTimeInput
                        source="date"
                        // defaultValue={new Date()}
                        fullWidth={true}
                        formClassName={classes.date}
                        label="resources.visits.fields.date"
                    />
                    <SelectInput
                        label="resources.visits.fields.status"
                        source="status"
                        choices={[
                            {
                                id: 'planned',
                                name: 'Planifié',
                            },
                            {
                                id: 'inprogress',
                                name: 'En Cours',
                            },
                            {
                                id: 'done',
                                name: 'Terminé',
                            },
                        ]}
                        fullWidth={true}
                    />
                    {/* <ReferenceInput
                        autoFocus
                        source="intermediate.id"
                        reference="intermediates"
                        formClassName={classes.intermediates}
                        label="resources.visits.fields.intermediate"
                        validate={requiredValidate}
                        fullWidth
                    >
                        <AutocompleteInput
                            optionText="RS"
                            optionValue="id"
                            perPage={10}
                        />
                    </ReferenceInput> */}

                    <ReferenceField
                        label="resources.visits.fields.intermediate"
                        source="intermediate.id"
                        reference="intermediates"
                        link="show"
                    >
                        <TextField source="RS" />
                    </ReferenceField>
                </FormTab>

                <FormTab label="Evaluation">
                    {/* "evaluation": {
        "done": true,
        "appreciation": "very_weak",
        "online_subscriptions": false,
        "production": "good",
        "sinistre": "very_weak",
        "packs_calcul": false,
        "packs_number_by_usage": false,
        "packs_appellation": true,
        "assistance": true,
        "ppc": true,
        "taxi": true,
        "other_usage": true,
        "after_sales_quality": "hsghghgh"
    }, */}
                    <SectionTitle label="Accueil et Appréciation" />
                    {/* appreciation */}
                    <SectionTitle label="Appreciation" />
                    <SelectInput
                        source="evaluation.appreciation"
                        label=""
                        choices={[
                            { id: 'very_weak', name: 'Très faible' },
                            { id: 'weak', name: 'Faible' },
                            { id: 'average', name: 'Moyenne' },
                            { id: 'good', name: 'Bon' },
                            { id: 'excellent', name: 'Excellent' },
                        ]}
                        disabled
                    />

                    <SectionTitle label="Réalisation des souscriptions en mode connecté" />
                    <RadioButtonGroupInput
                        source="evaluation.online_subscriptions"
                        label=""
                        choices={[
                            { id: true, name: 'Oui' },
                            { id: false, name: 'Non' },
                        ]}
                        disabled
                    />
                    <SectionTitle label="Maitrise des procédures et Applications CAT" />
                    {/* production */}
                    {/* appreciation */}
                    <SectionTitle label="Production" />
                    <SelectInput
                        source="evaluation.production"
                        label=""
                        choices={[
                            { id: 'very_weak', name: 'Très faible' },
                            { id: 'weak', name: 'Faible' },
                            { id: 'average', name: 'Moyenne' },
                            { id: 'good', name: 'Bon' },
                            { id: 'excellent', name: 'Excellent' },
                        ]}
                        disabled
                    />
                    {/* sinistre */}
                    {/* appreciation */}
                    <SectionTitle label="Sinistre" />
                    <SelectInput
                        source="evaluation.sinistre"
                        label=""
                        choices={[
                            { id: 'very_weak', name: 'Très faible' },
                            { id: 'weak', name: 'Faible' },
                            { id: 'average', name: 'Moyenne' },
                            { id: 'good', name: 'Bon' },
                            { id: 'excellent', name: 'Excellent' },
                        ]}
                        disabled
                    />
                    <SectionTitle label="Packs" />
                    <RadioButtonGroupInput
                        source="evaluation.packs_calcul"
                        label="Calcul:"
                        choices={[
                            { id: true, name: 'Oui' },
                            { id: false, name: 'Non' },
                        ]}
                        disabled
                    />
                    <RadioButtonGroupInput
                        source="evaluation.packs_number_by_usage"
                        label="Nombre de Packs par usage :"
                        choices={[
                            { id: true, name: 'Oui' },
                            { id: false, name: 'Non' },
                        ]}
                        disabled
                    />
                    <RadioButtonGroupInput
                        source="evaluation.packs_appellation"
                        label="Appellation:"
                        choices={[
                            { id: true, name: 'Oui' },
                            { id: false, name: 'Non' },
                        ]}
                        disabled
                    />
                    <SectionTitle label="Produits accessoires" />
                    <RadioButtonGroupInput
                        source="evaluation.assistance"
                        label="Assistance"
                        choices={[
                            { id: true, name: 'Oui' },
                            { id: false, name: 'Non' },
                        ]}
                        disabled
                    />
                    <RadioButtonGroupInput
                        source="evaluation.ppc"
                        label="PPC"
                        choices={[
                            { id: true, name: 'Oui' },
                            { id: false, name: 'Non' },
                        ]}
                        disabled
                    />

                    <SectionTitle label="Développement de l’offre" />
                    <RadioButtonGroupInput
                        label="Taxi:"
                        source="evaluation.taxi"
                        choices={[
                            { id: true, name: 'Oui' },
                            { id: false, name: 'Non' },
                        ]}
                        disabled
                    />
                    <RadioButtonGroupInput
                        source="evaluation.other_usage"
                        label="Autre usage:"
                        choices={[
                            { id: true, name: 'Oui' },
                            { id: false, name: 'Non' },
                        ]}
                        disabled
                    />
                    {/* after_sales_quality */}
                    <TextField
                        source="evaluation.after_sales_quality"
                        label="Qualité de service après de vente:"
                        multiline={true}
                        fullWidth={true}
                        disabled
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

// export const PostEdit = (props) => (
//     <Edit {...props}>
//         <TabbedForm tabs={<TabbedFormTabs scrollable={true} />}>
//             ...
//         </TabbedForm>
//     </Edit>
// );

const VisitName: FC<FieldProps<Visit>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const useStyles = makeStyles(styles);

const requiredValidate = [required()];
// const Separator = () => <Box pt="1em" />;

export const SectionTitle = ({ label }: { label: string }) => {
    return (
        <Typography variant="h6" gutterBottom>
            {label}
        </Typography>
    );
};
export default VisitEdit;
