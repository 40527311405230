import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
// import { push } from 'react-router-redux';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
const API_URL = process.env.REACT_APP_ADMIN_REST_API_URL;

class PrintPdfVisitButton extends Component {
    handleClick = () => {
        const { record } = this.props;

        const url = `${API_URL}/visits/${record.id}/pdf`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `${'Report-' + Date.now() + '.pdf'}`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
    };

    render() {
        const { record } = this.props;
        return record.is_imported ? null : (
            <Button
                // className={classes.button}
                label="Imprimer"
                onClick={this.handleClick}
                variant="contained"
                // color="secondary"
                // disabled={record.status === 'opened'}
            >
                <PictureAsPdfIcon />
                Imprimer Evaluation
            </Button>
        );
    }
}

PrintPdfVisitButton.propTypes = {
    record: PropTypes.object,
};

export default PrintPdfVisitButton;
