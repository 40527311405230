import VisitIcon from './VisitIcon';

import VisitList from './VisitList';
import VisitCreate from './VisitCreate';
import VisitEdit from './VisitEdit';

export default {
    list: VisitList,
    create: VisitCreate,
    edit: VisitEdit,
    icon: VisitIcon,
};
