import React, { FC } from 'react';
import classnames from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link, useTranslate, useQueryWithStore } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { FieldProps, AppState, Order, Product } from '../types';


const useStyles = makeStyles({
    container: { minWidth: '35em', marginLeft: '1em' },
    rightAlignedCell: { textAlign: 'right' },
    boldCell: { fontWeight: 'bold' },
});

const CaParAnnee=(data) => {
    const classes = useStyles();
    const translate = useTranslate();
    data=data['ca']
    console.log('CaParAnnee')
    console.log(data);
    if(!data){
        return null
    }
    const dataParAnnees=(data.ParAnnee && Array.isArray(data.ParAnnee)&&data.ParAnnee.length)?data.ParAnnee:[];
    const dataParType=(data.ParType && Array.isArray(data.ParType) &&data.ParType.length)? data.ParType:[];

    return (
        <Paper className={classes.container} elevation={2}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {'Annee'}
                        </TableCell>
                        <TableCell className={classes.rightAlignedCell}>
                            {'Prime Nette'}
                        </TableCell>
                        <TableCell className={classes.rightAlignedCell}>
                            {'PrimeRc'}
                        </TableCell>
                        <TableCell className={classes.rightAlignedCell}>
                            {'PrimeGa'}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {dataParAnnees.map(
                    (item: any) =>
                   (
                        <TableRow key={item.product_id}>
                            <TableCell>
                            {item.Annee}
                            </TableCell>
                            <TableCell
                                className={classes.rightAlignedCell}
                            >
                                {Number(item['Prime Nette']).toFixed(2)}
                            </TableCell>
                            <TableCell
                                className={classes.rightAlignedCell}
                            >
                                {Number(item['PrimeRc']).toFixed(2)}
                            </TableCell>
                            <TableCell
                                className={classes.rightAlignedCell}
                            >
                                {Number(item['PrimeGa']).toFixed(2)}
                            </TableCell>
                        </TableRow>
                    )
                )}
           
                </TableBody>
            </Table>
        </Paper>
    );
};

export default CaParAnnee;