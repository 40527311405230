import React  ,{Fragment} from 'react';
import { InputProps } from 'ra-core';
import {
    Datagrid,
    TextField,
    Filter,
    List,
    SearchInput,
    EditButton,
} from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
// import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';
// import Chip from '@material-ui/core/Chip';
// import AvatarField from './AvatarField';
import NotificationBulkActionButton from './NotificationBulkActionButton';

// import ColoredNumberField from './ColoredNumberField';
// import MobileGrid from './MobileGrid';

const AccountManagerFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />

    </Filter>
);

// const useStyles = makeStyles({

// });

interface Props extends Omit<InputProps, 'source'> {
    source?: string;
}

const ClientsBulkActionButtons = props => (
    <Fragment>
        <NotificationBulkActionButton label="Reset Views" {...props} />
    </Fragment>
);

const AccountManagerList = (props: any) => {
    // const classes = useStyles();
    // const isXsmall = useMediaQuery<Theme>(theme =>
    //     theme.breakpoints.down('xs')
    // );
    return (
        <List
            {...props}
            filters={<AccountManagerFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={<ClientsBulkActionButtons />}
        >

                <Datagrid rowClick="edit">
                    {/* <AvatarField
                        size="45"
                    /> */}
                      <Avatar variant="rounded">CC</Avatar>

                    {/* <AccountManagerThumbnailField label="resources.accountmanagers.fields.name"/> */}
                    <TextField
                        source="name.first"
                        label="resources.accountmanagers.fields.first_name"
                    />
                     <TextField
                        source="name.last"
                        label="resources.accountmanagers.fields.last_name"
                    />

                    <TextField
                        source="email"
                        label="resources.accountmanagers.fields.email"
                    />
                     <TextField
                        source="region"
                        label="resources.accountmanagers.fields.region"
                    />
                    <TextField
                        source="gsmPhone"
                        label="resources.accountmanagers.fields.gsmPhone"
                    />
                     <TextField
                        source="fixPhone"
                        label="resources.accountmanagers.fields.fixPhone"
                    />

                    <EditButton />
                </Datagrid>

        </List>
    );
};

export default AccountManagerList;
