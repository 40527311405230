import React from 'react';
import {
    Create,
    SimpleForm,
    required,
    // DateInput,
    DateTimeInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    AutocompleteInput,
} from 'react-admin';

// import { DateInput, TimeInput, DateTimeInput } from 'react-admin-date-inputs';
// import MapField from './MapField';

// import {Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Styles } from '@material-ui/styles/withStyles';

// const validateEmail = email();

export const styles: Styles<Theme, any> = {
    accountManager: { width: 544 },
    date: { width: 544 },
    intermediates:{ width: 544 },

};



const useStyles = makeStyles(styles);

const VisitCreate = (props: any) => {
    const classes = useStyles();

    return (
        <Create {...props}>
            <SimpleForm redirect="list">

                <ReferenceInput
                    autoFocus
                    source="areaManager"
                    reference="areaManagers"
                    formClassName={classes.accountManager}
                    label="resources.visits.fields.areamanager"
                    validate={requiredValidate}
                    fullWidth
                >
                    <AutocompleteInput
                        optionText="name.full"
                        optionValue="id"
                        perPage={10}
                    />
                </ReferenceInput>

                <ReferenceArrayInput
                    autoFocus
                    source="accountManagers"
                    reference="accountManagers"
                    formClassName={classes.accountManager}
                    label="resources.visits.fields.accountmanager"
                    validate={requiredValidate}
                    fullWidth
                >
                    <AutocompleteArrayInput
                        optionText="name.full"
                        optionValue="id"
                        perPage={10}
                    />
                </ReferenceArrayInput>

               <DateTimeInput
                    source="date"
                    defaultValue={new Date()}
                    // type="datetime-local"
                    options={{ format: 'DD/MM/YYYY' }}
                    fullWidth={true}
                    formClassName={classes.date}
                    label="resources.visits.fields.date"
                />
                <ReferenceInput
                    source="intermediate"
                    reference="intermediates"
                    formClassName={classes.intermediates}
                    label="resources.visits.fields.intermediate"
                    validate={requiredValidate}
                    fullWidth
                >
                    <AutocompleteInput
                        optionText="RS"
                        optionValue="id"
                        perPage={10}
                    />
                </ReferenceInput>

            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];


export default VisitCreate;
