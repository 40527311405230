import AccountManagerIcon from './AccountManagerIcon';

import AccountManagerList from './AccountManagerList';
import AccountManagerCreate from './AccountManagerCreate';
import AccountManagerEdit from './AccountManagerEdit';

export default {
    list: AccountManagerList,
    create: AccountManagerCreate,
    edit: AccountManagerEdit,
    icon: AccountManagerIcon,
};
