// in src/NotFound.js
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

export default () => (
    <Card>
        <Title title="Module en cours de développement" />
        <CardContent>
            <center>
              <br/>
              <br/>
              <br/>
                <h1 style={{ color: '#312852' }}>
                    Module en cours de développement...
                </h1>
            </center>
        </CardContent>
    </Card>
);
