import React from 'react';
import { InputProps } from 'ra-core';
import { SearchInput,Filter,List,useShowController, TextField, DateField, ReferenceField, EditButton } from "react-admin";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';
// import Chip from '@material-ui/core/Chip';
import PackParAnnee from './PackParAnnee';
import PackParType from './PackParType';
import PackParUsage from './PackParUsage';


import { FieldProps, Customer } from '../types';
const IntermediateFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

// const useStyles = makeStyles({

// });

const cardStyle = {
    width: 500,
    minHeight: 300,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top'
};
const CustomerField=(record: any) =>
    record ? (
        <Typography>
            {record.first_name} {record.last_name}
            <br />
            {record.address}
            <br />
            {record.city}, {record.zipcode}
        </Typography>
    ) : null;

    const useStyles = makeStyles(theme => ({
        // root: { width: 600, margin: 'auto' },
        spacer: { height: 20 },
        // invoices: { margin: '10px 0' },
        root: {
          width: '100%',
        },
        heading: {
          fontSize: theme.typography.pxToRem(15),
          flexBasis: '33.33%',
          flexShrink: 0,
        },
        secondaryHeading: {
          fontSize: theme.typography.pxToRem(15),
          color: theme.palette.text.secondary,
        },
      }));
const CustomGrid = ({ ids, data, basePath }) => {

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);


    const handleChange = panel => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return(
    <div className={classes.root}>
      <ExpansionPanel expanded={new String(expanded).valueOf()  === "panel1"} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Packs par An :</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <PackParAnnee {...data}/>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={new String(expanded).valueOf()  === "panel2"} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Packs par Type :</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
         <PackParType {...data}/>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={new String(expanded).valueOf()  === "panel3"} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Packs par Usage :</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
         <PackParUsage {...data}/>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      </div>
)};
CustomGrid.defaultProps = {
    data: {},
    ids: [],
};


interface Props extends Omit<InputProps, 'source'> {
    source?: string;
}

const PackStatsList = (props: any) => {

    return (
        <List
            {...props}
            filters={<IntermediateFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            bulkActionButtons={false}
            exporter={false}
            pagination={null}
        >
              <CustomGrid  {...props}/>



        </List>
    );
};

export default PackStatsList;
