import React, { FC } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Visit, FieldProps } from '../types';

interface Props extends FieldProps<Visit> {
    className?: string;
    size?: string;
}
const AvatarField: FC<Props> = ({ record, size, className }) =>
    record ? (
        <Avatar
            // src={`${process.env.REACT_APP_PUBLIC_URL+record.imageUrl}?size=${size}x${size}`}
            sizes={size}
            style={{ width: size, height: size }}
            className={className}
            variant="rounded"
            alt="Visit" 
    
        > V</Avatar>
    ) : null;

AvatarField.defaultProps = {
    size: '25',
};

export default AvatarField;
