import React, { FC } from 'react';
import {
    Edit,
    TextInput,
    Toolbar,
    PasswordInput,
    required,
    SelectInput,
    FormWithRedirect,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';

import { Styles } from '@material-ui/styles/withStyles';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Aside from './Aside';
import FullNameField from './FullNameField';
import { AccountManager, FieldProps } from '../types';

import { SectionTitle } from './AccountManagerCreate';

export const styles: Styles<Theme, any> = {
    image: { width: 544 },
    name: { width: 544 },
};

const AccountManagerEdit = (props: any) => {
    return (
        <Edit
            undoable={false}
            title={<AccountManagerName />}
            aside={<Aside />}
            component="div"
            {...props}
        >
            <AccountManagerForm />
        </Edit>
    );
};

const AccountManagerName: FC<FieldProps<AccountManager>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const useStyles = makeStyles(styles);

export const validatePasswords = ({
    password,
    confirm_password,
}: {
    password: string;
    confirm_password: string;
}) => {
    const errors = {} as any;

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = [
            'resources.accountmanagers.errors.password_mismatch',
        ];
    }

    return errors;
};

const AccountManagerForm = (props: any) => {
    // const translate = useTranslate();
    const classes = useStyles();
    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            validate={validatePasswords}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <SectionTitle label="resources.accountmanagers.fieldGroups.identity" />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="name.first"
                                                validate={requiredValidate}
                                                label="resources.accountmanagers.fields.first_name"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="name.last"
                                                validate={requiredValidate}
                                                label="resources.accountmanagers.fields.last_name"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                type="email"
                                                source="email"
                                                validate={requiredValidate}
                                                label="resources.accountmanagers.fields.email"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <SelectInput
                                                source="region"
                                                label="resources.accountmanagers.fields.region"
                                                choices={[
                                                    {
                                                        id: 'nord',
                                                        name: 'NORD',
                                                    },
                                                    { id: 'sud', name: 'SUD' },
                                                ]}
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <SectionTitle label="resources.accountmanagers.fieldGroups.address" />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="address"
                                                label="resources.accountmanagers.fields.address"
                                                formClassName={classes.address}
                                                multiline={true}
                                                fullWidth={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="zipcode"
                                                label="resources.accountmanagers.fields.zipcode"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="city"
                                                label="resources.accountmanagers.fields.city"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <SectionTitle label="resources.accountmanagers.fieldGroups.phone" />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="gsmPhone"
                                                validate={requiredValidate}
                                                label="resources.accountmanagers.fields.gsmPhone"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="fixPhone"
                                                label="resources.accountmanagers.fields.fixPhone"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="fax"
                                                label="resources.accountmanagers.fields.fax"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>

                                    <SectionTitle label="resources.accountmanagers.fieldGroups.password" />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="password"
                                                label="resources.accountmanagers.fields.password"
                                                fullWidth
                                            />
                                            
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="confirm_password"
                                                label="resources.accountmanagers.fields.confirm_password"
                                                accountmanagers
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="accountmanagers"
                            
                        />
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];
// const Separator = () => <Box pt="1em" />;
export default AccountManagerEdit;
