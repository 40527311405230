import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink ,WithPermissions} from 'react-admin';

import SubMenu from './SubMenu';
import { AppState } from '../types';
// import color from '@material-ui/core/colors/amber';
import { makeStyles } from '@material-ui/core/styles';
// import { lightTheme } from './themes';
import DashboardIcon from '../dashboard/DashboardIcon';
import DashboardIcon2 from '@material-ui/icons/Dashboard';

/**
 * Modules
 */
import salesManagers from '../salesManagers';
import areaManagers from '../areaManagers';
import accountManagers from '../accountManagers';
import visits from '../visits';
import intermediates from '../intermediates';
import documentation from '../documentation';

const useStyles = makeStyles(theme => ({
    menuItem: {
        active: { fontWeight: 'bold', color: theme.palette.secondary.main },
    },
    icon: {
        color: theme.palette.secondary.main,
    },
}));

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
    });
    const classes = useStyles();
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };
    return (
        <div>
            {' '}
            <SubMenu
                handleToggle={() => handleToggle('menuSales')}
                isOpen={state.menuSales}
                sidebarIsOpen={open}
                name='resources.dashboard.name'
                icon={<DashboardIcon className={classes.icon} />}
                dense={dense}
            >

                <MenuItemLink
                    to={`/castats`}
                    primaryText={'Chiffres Affaires'}
                    leftIcon={<DashboardIcon2 color="primary" />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/pacstats`}
                    primaryText={'Packs'}
                    leftIcon={<DashboardIcon2 color="primary" />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/termstats`}
                    primaryText={'Termes'}
                    leftIcon={<DashboardIcon2 color="primary" />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/sinstats`}
                    primaryText={'Sinistres'}
                    leftIcon={<DashboardIcon2  color="primary"/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/attstates`}
                    primaryText={'Attestations Manquantes'}
                    leftIcon={<DashboardIcon2  color="primary"/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />

                {/* <DashboardMenuItem
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    leftIcon={<DashboardIcon className={classes.icon} />}
                /> */}
            </SubMenu>

            <WithPermissions
            render={({ permissions }) => (
                permissions === 'salesManager'
                    ?<MenuItemLink
                    className={classes.menuItem}
                    to={`/salesManagers`}
                    primaryText={translate(`resources.salesmanagers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<salesManagers.icon className={classes.icon} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                    : null
            )}
        />

<WithPermissions
            render={({ permissions }) => (
                permissions === 'salesManager'
                    ? <MenuItemLink
                    className={classes.menuItem}
                    to={`/areaManagers`}
                    primaryText={translate(`resources.areamanagers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<areaManagers.icon className={classes.icon} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                    : null
            )}
        />
<WithPermissions
            render={({ permissions }) => (
                permissions === 'salesManager'
                    ? <MenuItemLink
                    className={classes.menuItem}
                    to={`/accountManagers`}
                    primaryText={translate(`resources.accountmanagers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<accountManagers.icon className={classes.icon} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                    : null
            )}
        />


<WithPermissions
            render={({ permissions }) => (
                permissions === 'salesManager'
                    ? <MenuItemLink
                    className={classes.menuItem}
                    to={`/intermediates`}
                    primaryText={translate(`resources.intermediates.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<intermediates.icon className={classes.icon} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                    : null
            )}
        />


<WithPermissions
            render={({ permissions }) => (
                permissions === 'salesManager'
                    ? <MenuItemLink
                    className={classes.menuItem}
                    to={`/visits`}
                    primaryText={translate(`resources.visits.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<visits.icon className={classes.icon} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                    : null
            )}
        />


<WithPermissions
            render={({ permissions }) => (
                permissions === 'salesManager'
                    ?  <MenuItemLink
                    className={classes.menuItem}
                    to={`/documents`}
                    primaryText={translate(`resources.documentation.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<documentation.icon className={classes.icon} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                    : null
            )}
        />


            {isXSmall && logout}
        </div>
    );
};

export default Menu;
