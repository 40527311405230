import React, { useState, useEffect } from 'react';
import { Admin, Resource, resolveBrowserLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';
import frenchMessages from './i18n/fr';

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';

/**
 * Modules
 */
import accountManagers from './accountManagers';
import salesManagers from './salesManagers';
import visits from './visits';
import areaManagers from './areaManagers';
import intermediates from './intermediates';
import documentation from './documentation';
import caStats from './caStats';
import packStats from './packStats';
import termeStats from './termeStats';
import sinistreStats from './sinistreStats';
import attestationStats from './attestationStats';

/**
 * Not found Page
 */
import NotFound from './NotFound';

import dataProviderFactory from './dataProvider';
// import fakeServerFactory from './fakeServer';

const messages = {
    fr: frenchMessages,
    en: englishMessages,
};

const i18nProvider = polyglotI18nProvider(
    locale => (messages[locale] ? messages[locale] : messages.en),
    resolveBrowserLocale()
);

const App = () => {
    const [dataProvider, setDataProvider] = useState(null);

    useEffect(() => {
        let restoreFetch;

        const fetchDataProvider = async () => {
            const dataProviderInstance = await dataProviderFactory(
                process.env.REACT_APP_DATA_PROVIDER
            );
            restoreFetch = dataProviderInstance;

            setDataProvider(
                // GOTCHA: dataProviderInstance can be a function
                () => dataProviderInstance
            );
        };

        fetchDataProvider();

        return restoreFetch;
    }, []);

    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader"> C.A.T </div>
            </div>
        );
    }

    return (
        <Admin
            title=""
            catchAll={NotFound}
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            // dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
            {(permissions: string) => [
                //Stats
                 //  salesManager or areaManager can view caStats
                 permissions === 'salesManager' || permissions === 'areaManager' ? (
                    <Resource name="castats" {...caStats} />
                ) : null,
                permissions === 'salesManager' || permissions === 'areaManager' ? (
                    <Resource name="pacstats" {...packStats} />
                ) : null,
                permissions === 'salesManager' || permissions === 'areaManager' ? (
                    <Resource name="termstats" {...termeStats} />
                ) : null,
                permissions === 'salesManager' || permissions === 'areaManager' ? (
                    <Resource name="sinstats" {...sinistreStats} />
                ) : null,
                permissions === 'salesManager' || permissions === 'areaManager' ? (
                    <Resource name="attstates" {...attestationStats} />
                ) : null,
                // Only salesManager can view salesManagers
                 permissions === 'salesManager' ? (
                    <Resource name="salesManagers" {...salesManagers} />
                ) : null,
                // Only salesManager can view accountManagers
                permissions === 'salesManager' ? (
                    <Resource name="salesManagers" {...salesManagers} />
                ) : null,
                 // Only salesManager can view accountManagers
                permissions === 'salesManager' ? (
                    <Resource name="accountManagers" {...accountManagers} />
                ) : null,
                // Only salesManager can view visits
                permissions === 'salesManager' ? (
                    <Resource name="visits" {...visits} />
                ) : null,
                          // Only salesManager can view salesManagers
                    permissions === 'salesManager' ? (
                        <Resource name="areaManagers" {...areaManagers} />
                    ) : null,
                 // Only salesManager can view visits
                 permissions === 'salesManager' ? (
                  <Resource name="intermediates" {...intermediates} />
              ) : null,
                // Only salesManager can view visits
                permissions === 'salesManager' ? (
                    <Resource name="documents" {...documentation} />
                ) : null,
            ]}
        </Admin>
    );
};

export default App;
