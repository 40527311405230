import React, { SVGProps } from 'react';

const VisitIcon = (props: SVGProps<SVGSVGElement>) => (
<svg width="39px" height="39px" viewBox="0 0 39 39" version="1.1"  xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="documentation" fill="#1199BC">
            <path d="M6.0038193,17 C4.66539357,17 4.66539357,19 6.0038193,19 C11.3344762,19 16.6641566,19 21.994423,19 C23.3351923,19 23.3351923,17 21.994423,17 C16.6641566,17 11.3344762,17 6.0038193,17 Z" id="Path"></path>
            <path d="M21.9942847,21 C16.6640617,21 11.3344247,21 6.00381113,21 C4.66539629,21 4.66539629,23 6.00381113,23 C11.3344247,23 16.6640617,23 21.9942847,23 C23.3352384,23 23.3352384,21 21.9942847,21 Z" id="Path"></path>
            <path d="M21.9942847,24 C16.6640617,24 11.3344247,24 6.00381113,24 C4.66539629,24 4.66539629,26 6.00381113,26 C11.3344247,26 16.6640617,26 21.9942847,26 C23.3352384,25.9998119 23.3352384,24 21.9942847,24 Z" id="Path"></path>
            <path d="M21.9942847,28 C16.6640617,28 11.3344247,28 6.00381113,28 C4.66539629,28 4.66539629,30 6.00381113,30 C11.3344247,30 16.6640617,30 21.9942847,30 C23.3352384,29.9998118 23.3352384,28 21.9942847,28 Z" id="Path"></path>
            <path d="M21.9973117,31 C16.6648754,31 11.333025,31 6.00312772,31 C4.66562409,31 4.66562409,33 6.00312772,33 C11.333025,33 16.6648754,33 21.9973117,33 C23.3342294,32.9998119 23.3342294,31 21.9973117,31 Z" id="Path"></path>
            <path d="M0,9 L0,39 L29,39 L29,13.1621878 L23.6115243,9 L0,9 Z M26.4935602,37.0026999 L2.50833677,37.0026999 L2.50833677,10.9974512 L22.3206499,10.9974512 L22.3206499,14.1663535 L26.4916632,14.1663535 L26.4916632,37.0026999 L26.4935602,37.0026999 Z" id="Shape" fillRule="nonzero"></path>
            <polygon id="Path" points="5 4 5 7.47237824 7.50825473 7.47237824 7.50825473 5.99671571 27.3208684 5.99671571 27.3208684 9.16564998 31.4926937 9.16564998 31.4926937 32.0026798 30.3907916 32.0026798 30.3907916 34 34 34 34 8.1614741 28.6117005 4"></polygon>
            <polygon id="Path" points="33.6108578 0 10 0 10 3.47309883 12.5072571 3.47309883 12.5072571 1.99745118 32.320051 1.99745118 32.320051 5.16635352 36.4917945 5.16635352 36.4917945 28.0026999 35.3908624 28.0026999 35.3908624 30 39 30 39 4.16218776"></polygon>
        </g>
    </g>
</svg>
);

export default VisitIcon;
