import React from 'react';
import { InputProps } from 'ra-core';
import {
    Datagrid,
    TextField,
    Filter,
    List,
    SearchInput,
    EditButton,
    DateInput,
} from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
// import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';
import AvatarField from './AvatarField';

// import ColoredNumberField from './ColoredNumberField';
// import MobileGrid from './MobileGrid';

const VisitFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <DateInput source="date" />
    </Filter>
);

// const useStyles = makeStyles({

// });

interface Props extends Omit<InputProps, 'source'> {
    source?: string;
}

const VisitList = (props: any) => {
    // const classes = useStyles();
    // const isXsmall = useMediaQuery<Theme>(theme =>
    //     theme.breakpoints.down('xs')
    // );
    return (
        <List
            {...props}
            filters={<VisitFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >

                <Datagrid rowClick="edit">
                <Avatar variant="rounded">D</Avatar>
                      <TextField
                        source="title"
                        label="resources.documentation.fields.title"
                    />
                     <TextField
                        source="type"
                        label="resources.documentation.fields.type"
                    />

                    <EditButton />
                </Datagrid>

        </List>
    );
};

export default VisitList;
