import React, { SVGProps } from 'react';

const AccountManagerIcon = (props: SVGProps<SVGSVGElement>) => (


<svg width="39px" height="37px" viewBox="0 0 39 37" version="1.1"  xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="manager" fill="#1199BC">
            <path d="M14.5,12 C18.6356006,12 22,9.30835898 22,6 C22.0001519,2.69151952 18.6356006,0 14.5,0 C10.3643994,0 7,2.69151952 7,6 C7,9.30848048 10.3645513,12 14.5,12 Z" id="Path"></path>
            <path d="M31,23 C26.5887261,23 23,26.1402607 23,30 C23,33.8598646 26.5888694,37 31,37 C35.4111306,37 39,33.8597393 39,30 C39,26.1402607 35.4112739,23 31,23 Z M35.8729721,28.5461268 L30.6451671,33.1204563 C30.3821581,33.3504638 30.0376392,33.4655302 29.6929771,33.4655302 C29.348315,33.4655302 29.0037962,33.3504638 28.7407872,33.1204563 L26.1268846,30.8332916 C25.6010099,30.3731512 25.6010099,29.6270995 26.1268846,29.1669591 C26.6529026,28.7069441 27.5052466,28.7069441 28.0312645,29.1669591 L29.6929771,30.621083 L33.9685922,26.8797944 C34.4946102,26.4197794 35.3469541,26.4197794 35.8729721,26.8797944 C36.3988468,27.3399348 36.3988468,28.0859865 35.8729721,28.5461268 Z" id="Shape" fillRule="nonzero"></path>
            <path d="M28,22.0220888 L28,20.900835 C28,18.4824115 26.2426242,16.3379636 23.6440305,15.5851419 L23.6319529,15.5816673 L19.8309748,15.0250941 C19.5074966,14.9370718 19.1617547,15.088537 19.0454888,15.3707486 L14.7327371,25.8354844 C14.4839077,26.4391573 13.5184205,26.4391573 13.2695912,25.8354844 L8.95683943,15.3707486 C8.86283722,15.1428431 8.61968288,15 8.35906684,15 C8.29722328,15 4.37052089,15.5808952 4.37052089,15.5808952 C1.75053658,16.35302 0,18.5009424 0,20.9297896 L0,29.6372982 C0,30.942189 1.19612724,32 2.67164187,32 L20.6288295,32 C20.573243,31.6159966 20.5413754,31.2256875 20.5413754,30.828558 C20.5415209,26.664103 23.694815,23.1407689 28,22.0220888 Z" id="Path"></path>
            <path d="M15.7487877,14.236618 C15.5637959,14.0792889 15.2934233,14 15.0200129,14 L12.9799871,14 C12.7064168,14 12.4360442,14.079164 12.2512123,14.236618 C11.9648509,14.4802285 11.9232797,14.8320964 12.1266587,15.1071727 L13.2171027,16.3910285 L12.7065767,19.7541264 L13.7117998,21.8424836 C13.8098118,22.0525055 14.1901882,22.0525055 14.2882002,21.8424836 L15.2934233,19.7541264 L14.7828973,16.3910285 L15.8733413,15.1071727 C16.0767203,14.8320964 16.0351491,14.4802285 15.7487877,14.236618 Z" id="Path"></path>
        </g>
    </g>
</svg>
);

export default AccountManagerIcon;
