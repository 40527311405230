import React, { SVGProps } from 'react';

const AreaManagerIcon = (props: SVGProps<SVGSVGElement>) => (

<svg id="Capa_1"  height="37" viewBox="0 0 511.997 511.997" width="39" xmlns="http://www.w3.org/2000/svg">
<g id="manager" fill="#1199BC">
<path d="m221.481 481.997h-55.482c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15h-55.482c18.681-20.087 45.756-50.825 72.239-86.705 57.955-78.514 87.341-145.904 87.341-200.295 0-106.994-87.039-194.47-194.025-194.997-.049 0-.099 0-.147 0-106.985.527-194.024 88.003-194.024 194.997 0 54.392 29.386 121.781 87.341 200.295 26.483 35.88 53.558 66.619 72.239 86.705zm34.518-451.997c90.495.482 164.098 74.485 164.098 164.997 0 47.167-28.174 110.267-81.477 182.479-32.645 44.225-66.114 80.292-82.621 97.336-16.507-17.044-49.976-53.11-82.621-97.336-53.303-72.212-81.477-135.312-81.477-182.479 0-90.512 73.603-164.515 164.098-164.997z"/><path d="m390.999 194.997c0-74.439-60.561-135-135-135s-135 60.561-135 135 60.561 135 135 135 135-60.56 135-135zm-180 94.856v-4.855c0-24.813 20.187-45 45-45s45 20.187 45 45v4.855c-13.647 6.5-28.905 10.145-45 10.145s-31.354-3.645-45-10.145zm-60-94.856c0-57.897 47.103-105 105-105s105 47.103 105 105c0 29.317-12.082 55.86-31.522 74.928-6.996-34.156-37.284-59.928-73.478-59.928s-66.482 25.771-73.478 59.928c-19.44-19.067-31.522-45.611-31.522-74.928z"/><path d="m300.999 164.997c0-24.813-20.187-45-45-45s-45 20.187-45 45 20.187 45 45 45 45-20.186 45-45zm-45 15c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.729 15-15 15z"/>
</g>
</svg>
);

export default AreaManagerIcon;
