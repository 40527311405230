import React from 'react';
import { AppBar, UserMenu } from 'react-admin';
import Typography from '@material-ui/core/Typography';
// import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';

import HorizontalLogo from './HorizontalLogo';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

// const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
//     const translate = useTranslate();
//     return (
//         <MenuItemLink
//             ref={ref}
//             to="/configuration"
//             primaryText={translate('pos.configuration')}
//             leftIcon={<SettingsIcon />}
//             onClick={props.onClick}
//         />
//     );
// });

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        {/* disabled in this version
        <ConfigurationMenu /> 
        */}
    </UserMenu>
);

const CustomAppBar = (props: any) => {
    const classes = useStyles();
    return (
        <AppBar {...props} userMenu={<CustomUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <HorizontalLogo />
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;
