import IntermediateIcon from './IntermediateIcon';

import IntermediateList from './IntermediateList';
import IntermediateCreate from './IntermediateCreate';
import IntermediateEdit from './IntermediateEdit';
import IntermediateShow from './IntermediateShow';

export default {
    list: IntermediateList,
    create: IntermediateCreate,
    // edit: IntermediateEdit,
    show: IntermediateShow,
    icon: IntermediateIcon,
};
