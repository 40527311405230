import React, { FC, Fragment } from 'react';
import classnames from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Link, useTranslate, useQueryWithStore } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    container: { minWidth: '35em', marginLeft: '1em' },
    rightAlignedCell: { textAlign: 'right' },
    boldCell: { fontWeight: 'bold' },
});

const PackParType=(data) => {
    const classes = useStyles();
    const translate = useTranslate();
    data=data['pack']

    if(!data){
        return null
    }
    const dataParAnnees=(data.ParAnnee && Array.isArray(data.ParAnnee)&&data.ParAnnee.length)?data.ParAnnee:[];
    const dataParType=(data.ParType && Array.isArray(data.ParType) &&data.ParType.length)? data.ParType:[];

    return (
        <Fragment>
                  {dataParType.map(
                    (dT: any) =>
                   (
                    <Paper className={classes.container} elevation={2}>
                         <Typography variant="h3" component="h4">{dT.Annee}</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {'Pack'}
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {'Prime Nette'}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {dT.data.map(
                            (item: any) =>
                           (
                                <TableRow key={item.product_id}>
                                    <TableCell>
                                    {item.Pack}
                                    </TableCell>
                                    <TableCell
                                        className={classes.rightAlignedCell}
                                    >
                                        {Number(item['Prime Nette']).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                   
                        </TableBody>
                    </Table>
                </Paper>

                   ))}
        </Fragment>
       
    );
};

export default PackParType;